export const routes = {
  root: '/',
  home: {
    root: '/sign-up',
    setPassword: '/sign-up/set-password',
    chanePassword: '/change-password',
  },
  brokerDetails: '/sign-up/broker-details',
  contactUs: '/sign-up/contact-us',
  dashboard: {
    root: '/broker/dashboard',
    manageAccount: '/broker/manage-account',
    referrals: '/broker/referrals',
    budgetCalculator: '/broker/budget-calculator',
    resources: '/broker/resources',
    faq: '/broker/faq',
    trackReferrals: '/broker/track-referrals',
  },
  referral: {
    root: '/referral/client-details',
    contactUs: '/referral/contact-us',
  },
  login: {
    root: '/login',
    forgotPassword: '/login/forgot-password',
  },
  budgetInPrinciple: {
    root: '/budget-in-principle/personal-details',
    income: '/budget-in-principle/income',
    expenditure: '/budget-in-principle/expenditure',
    qualifyingCriteria: '/budget-in-principle/qualifying-criteria',
    property: '/budget-in-principle/property',
    contactUs: '/budget-in-principle/contact-us',
    notEligible: '/budget-in-principle/not-eligible',
  },
};

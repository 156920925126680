import Link from 'next/link';
import { Props } from './types';
import Image from 'next/image';
import routes from '@/constants/routes/index';

import getConfig from 'next/config';

const Navigation = ({ navigationExpanded, pageName }: Props): JSX.Element => {
  const { publicRuntimeConfig } = getConfig();
  return (
    <>
      <ul
        className={`top-0 lg:top-auto lg:flex justify-between w-full lg:w-auto mt-[72px] md:mt-[80px] lg:mt-0 pb-[100%] lg:pb-0 bg-grey-800 items-center duration-700 ease absolute lg:relative lg:left-auto ${
          navigationExpanded ? 'left-0' : 'left-[100%]'
        }`}
      >
        <li className="flex justify-around lg:justify-between my-3 md:my-8 lg:my-0">
          <Link href={routes.dashboard.root}>
            <a
              className={`text-base md:text-2xl lg:text-sm !leading-[350%] lg:!leading-[18px] font-bold lg:font-black lg:tracking-wider ${
                pageName === 'dashboard' ? 'text-brown-400' : 'text-white'
              } hover:text-brown-400 hover:border-b-[1px] lg:hover:border-b-0 lg:mr-[50px] lg:uppercase`}
            >
              Dashboard
            </a>
          </Link>
        </li>
        {/* <li className="flex justify-around lg:justify-between my-3 md:my-8 lg:my-0">
          <Link href={routes.dashboard.referrals}>
            <a
              className={`text-base md:text-2xl lg:text-sm !leading-[350%] lg:!leading-[18px] font-bold lg:font-black lg:tracking-wider  ${
                pageName === 'referrals' ? 'text-brown-400' : 'text-white'
              } hover:text-brown-400 hover:border-b-[1px] lg:hover:border-b-0 lg:mr-[50px] lg:uppercase`}
            >
              Referrals
            </a>
          </Link>
        </li> */}
        <li className="flex justify-around lg:justify-between my-3 md:my-8 lg:my-0">
          <Link href={publicRuntimeConfig.NEXT_FAQ_BUDGET_CALCULATOR}>
            <a
              target="_blank"
              className={`text-base md:text-2xl lg:text-sm !leading-[350%] lg:!leading-[18px] font-bold lg:font-black lg:tracking-wider ${
                pageName === 'budget-calculator'
                  ? 'text-brown-400'
                  : 'text-white'
              } hover:text-brown-400 hover:border-b-[1px] lg:hover:border-b-0 lg:mr-[50px] lg:uppercase`}
            >
              Calculator
            </a>
          </Link>
        </li>
        {/* <li className="flex justify-around lg:justify-between my-3 md:my-8 lg:my-0">
          <Link href={routes.dashboard.resources}>
            <a
              className={`text-base md:text-2xl lg:text-sm !leading-[350%] lg:!leading-[18px] font-bold lg:font-black lg:tracking-wider ${
                pageName === 'resources' ? 'text-brown-400' : 'text-white'
              } hover:text-brown-400 hover:border-b-[1px] lg:hover:border-b-0 lg:mr-[50px] lg:uppercase`}
            >
              Resources
            </a>
          </Link>
        </li> */}
        <li className="flex justify-around lg:justify-between my-3 md:my-8 lg:my-0">
          <Link href={publicRuntimeConfig.NEXT_FAQ_PAGE}>
            <a
              target="_blank"
              className={`text-base md:text-2xl lg:text-sm !leading-[350%] lg:!leading-[18px] font-bold lg:font-black lg:tracking-wider ${
                pageName === 'faq' ? 'text-brown-400' : 'text-white'
              } hover:text-brown-400 hover:border-b-[1px] lg:hover:border-b-0 lg:inline-block lg:mr-[37px] lg:uppercase  lg:after:content-[''] relative lg:after:h-20 lg:after:absolute-center-y  lg:after:w-[1px] lg:after:-right-4 2xl:after:-right-[37px] lg:after:bg-landing-darkGray lg:after:transform lg:after:opacity-70 lg:after:h-[33px]`}
            >
              FAQs
            </a>
          </Link>
        </li>
        <hr className="lg:hidden w-[75%] mx-auto my-0" />
        <li className="flex justify-around lg:justify-between my-3 md:my-8 lg:my-0">
          <div>
            <Link href={routes.dashboard.manageAccount}>
              <a className="text-base md:text-2xl lg:text-base !leading-[350%] lg:!leading-[18px] font-bold lg:font-black text-white lg:flex items-center justify-between w-max">
                <span className="lg:mr-3 lg:ml-[37px]">Your account</span>
                <div className="hidden lg:flex">
                  <Image
                    src="/icons/people.svg"
                    alt="account"
                    width={24}
                    height={24}
                    className="mb-[3px]"
                  />
                </div>
              </a>
            </Link>
          </div>
        </li>
      </ul>
    </>
  );
};

export default Navigation;

import { useState } from 'react';

const useMobileNavigationToggle = () => {
  const [navigationExpanded, setNavigationExpanded] = useState(false);

  const toggleNavigation = () => {
    setNavigationExpanded((current) => !current);
  };

  return [navigationExpanded, toggleNavigation] as const;
};

export default useMobileNavigationToggle;

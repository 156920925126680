import { Props } from './types';
import Link from 'next/link';
import Image from 'next/image';
import LeftArrow from '@/public/icons/arrow-left.svg';
import Hamburger from '@/atoms/Hamburger';
import Navigation from '@/components/navigation/navigation';
import useMobileNavigationToggle from '@/shared/hooks/useMobileNavigationToggle';
import { useRouter } from 'next/router';
import routes from '@/constants/routes';
import getConfig from 'next/config';

const Header = ({
  isDashboardLayout = false,
  text = '',
}: Props): JSX.Element => {
  const { publicRuntimeConfig } = getConfig();
  const [navigationExpanded, toggleNavigation] = useMobileNavigationToggle();
  const router = useRouter();
  const splitRoute = router.asPath.split('/broker/');
  const pageName = splitRoute[splitRoute.length - 1];
  return (
    <>
      <nav
        className={`fixed z-50 flex items-center w-screen h-[72px] md:h-20 px-6 py-3 ${
          isDashboardLayout ? 'md:pl-[38px] md:pr-[40px]' : 'md:px-16'
        } bg-grey-800`}
      >
        <div
          className={`flex items-center ${
            isDashboardLayout
              ? 'justify-between  text-white'
              : 'justify-between'
          } w-full justify-items-start`}
        >
          {isDashboardLayout ? (
            <>
              <div className={`flex items-center justify-between w-full`}>
                <div className="hidden md:flex relative w-32 h-9 lg:w-[149px] lg:h-[37px]">
                  <Link href={publicRuntimeConfig.NEXT_CUSTOMER_APP + '/intermediaries'}>
                    <a>
                      <Image
                        src="/shared/keyzy-horizontal.svg"
                        alt="keyzy logo"
                        layout="fill"
                      />
                    </a>
                  </Link>
                </div>
                <div className="flex md:hidden relative w-9 h-9">
                  <Link href={publicRuntimeConfig.NEXT_CUSTOMER_APP + '/intermediaries'}>
                    <a>
                      <Image
                        src="/icons/keyzy-logo.svg"
                        alt="keyzy logo"
                        layout="fill"
                      />
                    </a>
                  </Link>
                </div>
                <p className="md:inline-block lg:hidden text-lg font-bold tracking-widest uppercase absolute-center-xy text-grey-300">
                  {text ? text : pageName}
                </p>
                <Navigation
                  navigationExpanded={navigationExpanded}
                  pageName={pageName}
                />
                <Hamburger
                  open={navigationExpanded}
                  onClick={toggleNavigation}
                  className="inline-block lg:hidden"
                />
              </div>
            </>
          ) : (
            <>
              {(pageName.includes('budget-in-principle') || pageName.includes('client-details')) && (
                <Link href={routes.dashboard.root}>
                  <a className="hidden md:flex md:text-lg md:font-bold text-white flex items-center">
                    <LeftArrow className="mr-4" />
                    Back to dashboard
                  </a>
                </Link>
              )}

              <div className="relative w-32 h-9 lg:w-44 lg:h-12">
                <Link href={publicRuntimeConfig.NEXT_CUSTOMER_APP + '/intermediaries'}>
                  <a>
                    <Image
                      src="/shared/keyzy-horizontal.svg"
                      alt="keyzy logo"
                      layout="fill"
                    />
                  </a>
                </Link>
              </div>
              {!!text.length && (
                <p className="hidden text-lg font-bold tracking-widest uppercase md:inline-block absolute-center-xy text-grey-300">
                  {text}
                </p>
              )}
              <div className="hidden md:flex w-32 h-9 lg:w-44 lg:h-12">
                &nbsp;
              </div>
            </>
          )}
        </div>
      </nav>
    </>
  );
};

export default Header;

import { useRouter } from 'next/router';

import Accordion from '@/atoms/Accordion';
import NavigationItems from './NavigationItems';
import { Props } from './types';
import { useNavigationContext } from '@/contexts/navigationContext/NavigationContext';

const NavigationTree = ({ data, className }: Props): JSX.Element => {
  const router = useRouter();
  const { applicantId } = router.query;

  const { onAnotherApplicantSelectHref, navigationVariant } =
    useNavigationContext();

  const numberOfNavItems = data?.filter(
    (applicants) => applicants.title,
  ).length;

  const hideNavigation = navigationVariant !== "basicApplicant" && numberOfNavItems === 1;

  const handleApplicantSelect = (id: string) => {
    router.push({
      pathname: onAnotherApplicantSelectHref,
      query: { applicantId: id },
    });
  };

  return (
    <div
      className={`z-10 flex lg:w-[275px] relative ${
        hideNavigation && 'hidden'
      }`}
    >
      <div className={`lg:w-[210px]  2xl:w-full ${className}`}>
        {data.map(({ id, title, items, noUpperCase = false, href, marginBottom }, idx) =>
          (numberOfNavItems > 1 || noUpperCase) && title ? (
            <Accordion
              key={id}
              title={noUpperCase ? title : title.toUpperCase()}
              onClick={() =>
                href
                  ? router.push({
                      pathname: href,
                      query: { applicantId },
                    })
                  : handleApplicantSelect(id as string)
              }
              active={applicantId === id || noUpperCase}
              className={`z-10 mb-10 text-lg ${
                href === router.pathname
                  ? 'font-bold text-brown-500'
                  : 'font-normal'
              }`}
              showSuffix={items?.every(({ showIcon }) => showIcon)}
              hideIcon={noUpperCase}
            >
              <NavigationItems
                items={items}
                id={id as string}
                className="mt-5 "
                variant="primary"
              />
            </Accordion>
          ) : (
            <NavigationItems
              items={items}
              id={id as string}
              key={idx}
              variant="secondary"
              marginBottom={marginBottom}
            />
          ),
        )}
      </div>
    </div>
  );
};

export default NavigationTree;

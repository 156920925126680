import { createContext, useContext } from 'react';
import { ContextValue } from './types';

const NavigationContext = createContext<ContextValue>({
  navigationData: [],
  updateNavigationData: () => undefined,
  createNavigationData: () => undefined,
  addNewApplicant: () => undefined,
  removeApplicant: () => undefined,
  onAnotherApplicantSelectHref: '',
  getFirstNotFinishedRoute: () => '',
});

export function useNavigationContext() {
  return useContext(NavigationContext);
}

export default NavigationContext;

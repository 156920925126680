import Accordion from '@/atoms/Accordion';
import Header from '@/components/shared/Header';
import NavigationTree from '@/containers/ApplicationFormLayout/components/NavigationTree';
import { useRouter } from 'next/router';
import Screen from '@/constants/screens';
import { Props } from './types';
import { useNavigationContext } from '@/contexts/navigationContext/NavigationContext';
import useMobileNavigationToggle from '@/shared/hooks/useMobileNavigationToggle';
import { useWindowSize } from '@/shared/hooks/useWindowSize';

function SendApplicationLayout({ children }: Props): JSX.Element {
  const router = useRouter();

  const [navigationExpanded, toggleNavigation] = useMobileNavigationToggle();
  const { navigationData, navigationVariant } = useNavigationContext();
  const { width } = useWindowSize();
  const pagesWithNavigation = [
    'basicApplicant',
    'basicApplicants',
    'brokerDetails',
    'clientDetails',
  ];

  const isContactUsPage =
    router.pathname === '/sign-up/contact-us' ||
    router.pathname === '/budget-in-principle/contact-us' ||
    router.pathname === '/budget-in-principle/not-eligible' ||
    router.pathname === '/referral/contact-us'
      ? true
      : false;
  const isLoginPage = router.pathname === '/login';
  const showNavigation = pagesWithNavigation.includes(navigationVariant || '');

  return (
    <>
      <Header text={isLoginPage ? 'Keyzy’s Intermediaries Log in' : ''} />

      <div className="flex flex-col min-h-full w-full bg-fixed lg:flex-row py-16 md:py-32 bg-gradient-grey-dark">
        <div className={showNavigation ? 'inline-block' : 'hidden'}>
          {width >= Screen.Tablet_1024 ? (
            <NavigationTree
              className={`flex-shrink-0 mt-24 mb-9 lg:ml-11`}
              data={navigationData}
            />
          ) : (
            <Accordion
              active={navigationExpanded}
              onClick={toggleNavigation}
              hideIcon
              className={`pt-16 bg-white w-screen transition-all duration-700 ease-in-out overflow-auto mx-auto px-8 md:px-16 ${
                navigationExpanded ? 'opacity-1' : 'hidden opacity-0'
              }
        `}
            >
              <NavigationTree
                className={`flex-shrink-0 mb-9`}
                data={navigationData}
              />
            </Accordion>
          )}
        </div>
        <main
          className={`px-2 sm:px-8 relative w-full h-full ${
            showNavigation && 'xl:-ml-[275px]'
          } mx-auto mt-8 md:mt-0`}
        >
          <div
            className={`w-full ${
              !isContactUsPage && 'md:w-[565px] xl:w-[645px]'
            }  md:mx-auto relative`}
          >
            {children}
          </div>
        </main>
      </div>
    </>
  );
}

export default SendApplicationLayout;

import Image from 'next/image';
import Link from 'next/link';

import { Props } from './types';
import blueCheck from 'public/icons/check-bg-blue.svg?url';

const NavigationItem = ({
  href,
  caption,
  showIcon,
  clickable,
  id,
  isActive,
  className = '',
}: Props): JSX.Element => {
  return (
    <li
      className={`relative border-l py-2 pr-3 flex justify-between items-center ${
        isActive ? 'border-brown-500' : 'border-grey-200'
      } ${className}`}
    >
      <Link href={{ pathname: href, query: id && { applicantId: id } }}>
        <a
          className={`text-lg transition-colors ease-out duration-200 ${
            isActive ? 'font-bold text-brown-500' : 'font-normal text-grey-900'
          } ${!clickable && 'pointer-events-none'}`}
        >
          {caption}
        </a>
      </Link>
      {showIcon && (
        <Image src={blueCheck} alt="checked" width={18} height={18} />
      )}
    </li>
  );
};

export default NavigationItem;

import NavigationItem from '../NavigationItem';
import { Props } from './types';
import { useRouter } from 'next/router';
import { useNavigationContext } from '@/contexts/navigationContext/NavigationContext';

const NavigationItems = ({
  items,
  id,
  variant,
  className,
  marginBottom
}: Props): JSX.Element => {
  const router = useRouter();
  const { navigationVariant } = useNavigationContext();
  const { sourceType } = router.query;

  const pathname = router.pathname.includes('[sourceType]')
    ? router.pathname.replace('[sourceType]', sourceType as string)
    : router.pathname;

  return (
    <nav>
      <ul className={className}>
        {items?.map(({ href, ...itemProps }, idx) => (
          <NavigationItem
            key={idx}
            id={id}
            isActive={pathname === href}
            className={`${variant === 'primary' ? 'pl-6' : 'border-opacity-0'} ${marginBottom && "mb-5"}`}
            href={href}
            {...itemProps}
          />
        ))}
      </ul>
    </nav>
  );
};

export default NavigationItems;

import { Props } from './types';
import HamburgerIcon from '@/public/icons/hamburger.svg';
import Close from '@/public/icons/plus.svg';

const Hamburger = ({ open, onClick, theme, className }: Props) => {
  return (
    <button
      className={`inline-block cursor-pointer relative w-8 h-8 ${className}`}
      onClick={onClick}
    >
      {open ? (
        <Close
          className={`w-full h-full transform rotate-45 stroke-2 ${
            theme === 'dark'
              ? 'stroke-white fill-white'
              : 'stroke-landing-blue fill-landing-blue'
          }`}
        />
      ) : (
        <HamburgerIcon
          className={`relative z-40 w-full h-full stroke-2 ${
            theme === 'dark'
              ? 'stroke-white  fill-white stroke-2'
              : 'stroke-landing-blue fill-landing-blue'
          }`}
        />
      )}
    </button>
  );
};

export default Hamburger;

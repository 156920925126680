import Image from 'next/image';
import { useRef } from 'react';

import { Props } from './types';
import chevron from 'public/icons/chevron-thin-up.svg?url';
import blueCheck from 'public/icons/check-bg-blue.svg?url';
import { useEffect, useState } from 'react';

const Accordion = ({
  title = '',
  children,
  active,
  onClick,
  icon = chevron,
  hideIcon = false,
  showSuffix,
  className,
}: Props) => {
  const contentSpace = useRef<HTMLDivElement>(null);
  const renderSuffix = showSuffix && !active;
  const [maxHeight, setMaxHeight] = useState(0);

  useEffect(() => {
    setMaxHeight(contentSpace?.current?.scrollHeight || 0);
  }, [contentSpace, children, active]);

  return (
    <div className={`relative ${className}`}>
      <div
        className="flex items-center justify-between cursor-pointer"
        onClick={onClick}
      >
        <span>{title}</span>
        {!hideIcon && (
          <Image
            className={`transform duration-700 ease ${active && 'rotate-180'}`}
            src={renderSuffix ? blueCheck : icon}
            width={renderSuffix ? '24' : '15'}
            height={renderSuffix ? '24' : '7'}
            alt="chevron"
          />
        )}
      </div>

      <div
        ref={contentSpace}
        style={{
          maxHeight: active ? `${maxHeight}px` : '0px',
        }}
        className="overflow-auto text-sm transition-all duration-700 ease-in-out no-scrollbar"
      >
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
